<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Privacy & Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Privacy & Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<br>
<br>

<section class="privecy-area ptb-100">
    <div class="container">
        <h2>Welcome to Endzert Privacy Policy</h2>
        <p> Welcome to endzert.com or the Endzert mobile application (the “Site”). This privacy
            policy (“Policy”) describes how Endzert International Inc. and its subsidiaries (“We”, “we”, “us”
            or
            Endzert) may use and disclose information that we may collect about you through the Site. </p>
        <h4>Collection and Use of Personal Information</h4>
        <p> When you use or register with the Site, we may collect certain “Personal Information,”
            which is information that identifies you as an individual or relates to an identifiable
            individual. The
            categories of Personal Information we may collect include: your name and contact information
            (email,
            address
            and phone number), your job search criteria and preferences, your employment experience,
            educational
            history, your skills, reference information, background information, other information contained
            in your
            resume, or any login ID or email address and password created by you. By providing your mobile
            phone
            number
            to us, you opt in and consent to receive text messages from us, which includes but is not
            limited to
            text
            messages sent through an automatic telephone dialing system. Consent to receive marketing text
            messages
            is
            not required as a condition of purchasing any services from us. If you do not wish to receive
            marketing
            text
            messages, do not provide us with your mobile phone number. </p>
        <p> We use Personal Information to register you with the Site, to provide you with
            information regarding employment opportunities and career-related information, and to otherwise
            provide
            you
            with requested information or services. We may from time to time use your Personal Information
            to send
            you
            automated email messages, text messages (message and data rates may apply), or marketing
            materials
            regarding
            our services, including employment information. We may also use Personal Information for our
            business
            purposes, such as data analysis, audits, and improving our services. </p>
        <h4>Disclosures of Personal Information</h4>
        <p> Endzert may disclose Personal Information to third parties in the following
            circumstances: (1) to our third-party service providers who perform business functions and
            services on
            our
            behalf; (2) to comply with applicable laws, the service of legal process, or if we reasonably
            believe
            that
            such action is necessary to (a) comply with the law requiring such disclosure; (b) protect the
            rights or
            property of Endzert or its affiliated companies; (c) prevent a crime or protect national
            security; or (d)
            protect the personal safety of the users or the public. We also may disclose or transfer
            information to
            a
            third party in the event of any reorganization, merger, sale, joint venture, assignment,
            transfer or
            other
            disposition of all or any portion of our business, assets or stock (including in connection with
            any
            bankruptcy or similar proceedings). </p>
        <h4>Collection and Use of Other Information</h4>
        <p> “Other Information” is any information that does not reveal your specific identity or
            does not directly relate to an identifiable individual. We may collect Other Information in a
            variety of
            ways, including: </p>
        <p> • Through your browser or device: Certain information is collected by most browsers or
            automatically through your device, such as your Media Access Control (MAC) address, computer
            type
            (Windows
            or Macintosh), screen resolution, operating system name and version, device manufacturer and
            model,
            language, and Internet browser type and version. We use this information to ensure that the Site
            functions
            properly. </p>
        <p> • Using cookies: Cookies are pieces of information stored on the computer that you are
            using. Cookies allow us to collect information such as browser type, time spent on the Site,
            pages
            visited,
            registrations, submissions, demographics, information requests, language preferences, and other
            traffic
            data. We use the information for security purposes, to facilitate navigation, to display
            information
            more
            effectively, and to personalize your experience. We also gather statistical information about
            use of the
            Site to enhance its functionality, understand how it is used and assist us with resolving
            questions
            about
            it. We may also use cookies and other technologies in online advertising campaigns to track
            responses to
            our
            ads. </p>
        <p> If you do not want information collected through the use of cookies, most browsers
            allow you to automatically decline cookies, or be given the choice of declining or accepting a
            particular
            cookie (or cookies) from a particular site. If, however, you do not accept cookies, you may
            experience
            some
            inconvenience in your use of the Site. </p>
        <p> • Using pixel tags and other similar technologies: Pixel tags (also known as web
            beacons and clear GIFs) may be used to, among other things, track the actions of Site users and
            email
            recipients, market our services to you, measure the success of our marketing campaigns and
            compile
            statistics about Site usage and response rates. </p>
        <p> • Analytics. We use third-party analytics providers such as Google Analytics on the
            Site. Google Analytics uses cookies and similar technologies to collect and analyze information
            about
            use of
            the Services and report on activities and trends. Google Analytics may also collect information
            regarding
            the use of other websites, apps and online resources. You can learn about Google’s practices by
            going to
            www.google.com/policies/privacy/partners/, and opt out by downloading the Google Analytics
            opt-out
            browser
            add-on, available at https://tools.google.com/dlpage/gaoptout. </p>
        <p> • IP Address: Your IP Address is a number that is automatically assigned to your
            computer by your Internet Service Provider. An IP Address may be identified and logged
            automatically in
            our
            server log files whenever a user accesses the Site, along with the time of the visit and the
            pages
            visited.
            We use IP Addresses for purposes such as calculating usage levels, diagnosing server problems,
            and
            administering the Site. We may also derive your approximate location from your IP Address. </p>
        <p> Please note that we may use and disclose Other Information for our business purposes.
        </p>
        <h4>Retargeting</h4>
        <p> We may use third-party advertising companies to serve ads regarding our services when
            you access and use other websites, based on information relating to your use of the Site. To do
            so,
            these
            companies may place or recognize a unique cookie on your browser (including through use of pixel
            tags).
            By
            utilizing the Site, you consent to the use of cookie or other tracking technologies to serve you
            retargeted
            advertising. </p>
        <h4>Third Party Services</h4>
        <p> This Policy does not address, and we are not responsible for, the privacy, information
            or other practices of any third parties, including any third party operating any site or service
            to
            which
            the Site links. </p>
        <h4>Security</h4>
        <p> We use reasonable organizational, technical and administrative measures designed to
            protect Personal Information within our organization. Unfortunately, no data transmission or
            storage
            system
            can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us
            is no
            longer secure, please immediately notify us in accordance with the “Contacting Us” section
            below. </p>
        <h4>Choices</h4>
        <p> If you no longer want to receive marketing-related emails from us on a going-forward
            basis, you may opt-out by using the mechanism contained in each such email. </p>
        <h4>SMS Terms</h4>
        <p> If you no longer wish to receive candidate/job matches or job status updates from us
            on a going-forward basis, you may opt-out by texting “STOP” to us at any time. </p>
        <h4>Use of Site by Minors</h4>
        <p> The Site is not directed to individuals under the age of eighteen (18) and, we do not
            knowingly collect Personal Information from individuals under 18. If you are a resident of
            California,
            under
            18 and a registered user of the Site, you may ask us to remove content or information that you
            have
            posted
            to the Site by writing to contact@endzert.com. Please note that your request does not ensure
            complete
            or
            comprehensive removal of the content or information, as, for example, some of your content may
            have been
            reposted by another user. </p>
        <h4>Jurisdictional Issues</h4>
        <p> The Site is controlled and operated by us from the United States and is not intended
            to subject us to the laws or jurisdiction of any state, country or territory other than that of
            the
            United
            States. </p>
        <h4>Revisions to the Policy</h4>
        <p> If we change this Policy we will post any updates here for your review. The “Last
            Updated” line at the top of this page indicates when this Policy was last revised. Any changes
            will
            become
            effective when we post the revised Policy on the Site. Your use of the Site following these
            changes
            means
            that you accept the revised Policy. </p>
    </div>
</section>

<br>
<br>