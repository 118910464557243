<div class="newsletter-section">
    <div class="container">
        <div class="newsletter-area">
            <h2>Subscribe Our Newsletter</h2>

            <div class="col-lg-6 offset-lg-3 p-0">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                    <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                </form>
            </div>
            <img src="assets/img/shapes/2.png" class="subscribe-shape" alt="shape">
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    </div>
                    <p>We are committed to providing strategic business and legal solutions to take your company to the
                        next level.</p>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/service"><i class="flaticon-right"></i> Services</a></li>
                        <li><a routerLink="/about"><i class="flaticon-right"></i> About Us</a></li>
                        <li><a routerLink="/contact"><i class="flaticon-right"></i> Contact</a></li>
                        <li><a routerLink="/faq"><i class="flaticon-right"></i> FAQ</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>

                    <ul>
                        <li><i class="flaticon-right"></i> 367 HILL ST, SAN LUIS OBISPO, CA 93405</li>
                        <li><a href="tel:+17476660376"><i class="flaticon-right"></i> +17476660376</a></li>
                        <li><a href="mailto:contact@endzert.com"><i class="flaticon-right"></i> contact@endzert.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 lh-1 text-start">
                    <p>© 2023 All Rights Reserved</p>
                </div>

                <div class="col-md-6 lh-1 text-end">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>