<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9">
                <div class="header-left-text">
                    <ul>
                        <li><a href="tel:+17476660376"><i class="flaticon-call"></i> +17476660376</a></li>
                        <li><a href="mailto:contact@endzert.com"><i class="flaticon-envelope"></i>
                                contact@endzert.com</a></li>
                        <li><i class="flaticon-maps-and-flags"></i> 367 HILL ST, SAN LUIS OBISPO, CA 93405</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-3">
                <div class="header-right text-end">
                    <div class="header-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo" width="200px">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home<i></i></a>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Services</a></li>

                        <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="other-option">
                        <div class="search-bar">
                            <i class="flaticon-search search-icon"></i>
                            <div class="search-form">
                                <form>
                                    <input type="text" placeholder="Search" class="search-input">
                                    <button type="submit">
                                        <i class="flaticon-search search-btn"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>