<app-navbar-one></app-navbar-one>

<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>About Us</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/1.jpg" alt="about image">
                </div>
            </div>

            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>

                    <p>Welcome to our business and legal consulting website, where we believe that your business is our
                        responsibility. We understand the challenges that come with running a successful business, and
                        we are here to help you navigate those challenges with ease.

                        <br><br>

                        At our firm, we provide a wide range of services to meet the needs of businesses of all sizes
                        and industries. From legal consultations and contract drafting to financial analysis and
                        strategic planning, we are dedicated to helping our clients achieve their goals.

                        <br><br>

                        Our team of experienced professionals brings a wealth of knowledge and expertise to the table,
                        and we work tirelessly to ensure that our clients receive the highest quality of service.
                        Whether you are starting a new business or looking to take your existing business to the next
                        level, we are here to help.

                        <br><br>

                        So if you're ready to take your business to new heights, look no further than our team of
                        experts. Your business is our responsibility, and we are committed to helping you achieve the
                        success you deserve. Contact us today to learn more about how we can help you.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="theory-section theory-style-two">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>My Theory is Customer Service is First</h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan.</p>

                    <div class="signature-box">
                        <img src="assets/img/signature.png" alt="signature">
                        <p>Babatunde Smithi</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/theory-img.png" alt="theory image">
            </div>
        </div>
    </div>
</div>

<div class="video-style-two video-style-three">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i
                            class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team Member</span>
            <h2>Meet Our Expert Team Member</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.jpg" alt="team member">
                        <br>
                        <br>
                        <div class="team-text">
                            <!-- <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div> -->
                            <h3>Jerry Hudson</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.jpg" alt="team member">
                        <br>
                        <br>
                        <div class="team-text">
                            <!-- <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div> -->
                            <h3>Juho Hudson</h3>
                            <p>Research Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.jpeg" alt="team member">
                        <br>
                        <br>
                        <div class="team-text">
                            <!-- <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div> -->
                            <h3>Sarah Tylor</h3>
                            <p>Marketing Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.jpg" alt="team member">
                        <br>
                        <br>
                        <div class="team-text">
                            <!-- <div class="team-social">
                                <ul>
                                    <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div> -->
                            <h3>Aikin Ward</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="service-section service-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 service-img"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Servicesfor Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i>Building Networking</li>
                        <li><i class="flaticon-checkmark"></i>Software Development</li>
                        <li><i class="flaticon-checkmark"></i>Business Strategy</li>
                        <li><i class="flaticon-checkmark"></i>Business Management</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="progress-section progress-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 p-0">
                <div class="progress-text">
                    <h2>We Always Try to be the Best Support to Our Clients as Possible</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>

                    <div class="progress-inner">
                        <p>Software Development</p>
                        <span>70%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="progress-inner">
                        <p>Digital Marketing</p>
                        <span>85%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="progress-inner">
                        <p>Business Management</p>
                        <span>95%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 progress-image"></div>
        </div>
    </div>
</div> -->

<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>