import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { NavbarOneComponent } from './components/layouts/navbar-one/navbar-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { TermsComponent } from './components/pages/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    FooterComponent,
    PreloaderComponent,
    NavbarOneComponent,
    AboutComponent,
    ServiceComponent,
    ErrorComponent,
    FaqComponent,
    PrivacyComponent,
    ContactComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
