<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Terms & Conditions</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<br>
<br>

<section class="privecy-area ptb-100">
    <div class="container">
        <h2>Welcome to Endzert Terms &amp; Conditions</h2>
        <p> Welcome to the Endzert websites and the Endzert mobile application together with any
            materials and services available therein, and successor site(s) thereto (individually referred to as the
            “Site” or collectively as the “Sites”), which are operated by Endzert International Inc. and its
            subsidiaries (“Endzert” or “we” or “us”). </p>
        <p> These Terms of Use (“Terms”) state the terms and conditions governing your use of
            and access to the Sites and constitute a legally binding contract between you and Endzert. These Terms
            incorporate any additional terms and conditions posted by Endzert through the Sites, or otherwise made
            available to you by Endzert. </p>
        <p> The Privacy Policy (“Policy”) describes how Endzert may use and disclose
            information that we may collect about all users and viewers through the Sites. </p>
        <p> PLEASE REVIEW THESE TERMS AND POLICY CAREFULLY. YOUR ACCESS TO AND/OR USE OF THE
            SITES CONSTITUTES YOUR ACCEPTANCE OF, AND CONSENT TO BE BOUND BY, THESE TERMS AND POLICY. IF YOU DO NOT
            ACCEPT AND CONSENT TO BE BOUND BY THESE TERMS AND POLICY, THEN YOU MUST NOT USE OR ACCESS THE SITES.
        </p>
        <p> We may change these Terms and/or Policy from time to time by notifying you of such
            changes by any reasonable means, including by posting revised Terms or Policy through the Sites. Any
            such changes will not apply to any dispute between you and us arising prior to the date on which we
            posted the revised Terms or Policy incorporating such changes, or otherwise notified you of such
            changes. </p>
        <p> In the event of a conflict between these Terms and/or Policy, and any other
            applicable terms or agreement fully executed by you and Endzert, the other applicable terms or agreement
            shall control. </p>
        <p> We may, at any time and without liability, modify or discontinue all or part of
            the Sites (including access to the Sites via any third-party links); or offer opportunities to some or
            all Site users. Any changes will become effective when we post the revised Terms on the Sites. Your use
            of the Sites following these changes means that you accept the revised Terms. </p>
        <p> Endzert is not a law firm and does not provide legal representation. Endzert project
            attorneys do not constitute a law firm among themselves. </p>
        <h3>1. ELIGIBILITY</h3>
        <p> By using the Sites, you affirm that you are of legal age to enter into these
            Terms. If you are an individual accessing or using the Sites on behalf of, or for the benefit of, any
            corporation, partnership or other entity with which you are associated (an “Organization”, or
            collectively also referred to as “you”), then you are agreeing to these Terms on behalf of yourself and
            such Organization, and you represent and warrant that you have the legal authority to bind such
            Organization to these Terms. References to “you” and “your” in these Terms will refer to both the
            individual using the Sites and to any such Organization. </p>
        <p> The Sites are controlled or operated (or both) from the United States, and are not
            intended to subject Endzert to any non-U.S. jurisdiction or law. The Sites may not be appropriate or
            available for use in some non-U.S. jurisdictions. Any use of the Sites is at your own risk, and you must
            comply with all applicable laws, rules and regulations in doing so. We may limit the Sites’ availability
            at any time, in whole or in part, to any person, geographic area or jurisdiction that we choose. </p>
        <h3>2. LICENSE</h3>
        <p> Subject to and conditioned on your compliance with these Terms, Endzert grants you
            a limited, non-exclusive, non-transferable, non-assignable, revocable license to access and use the
            Sites, and to download copies of the materials that we make available for download on the Sites, in each
            case solely for your personal and non-commercial use or for the purpose of doing business with Endzert.
        </p>
        <p> The Sites, including all content, information, and materials incorporated into or
            made available through the Sites, are the exclusive property of Endzert or its suppliers, and are
            protected by U.S. and international law. You agree not to access or use the Sites, or any content,
            information, or materials incorporated into or made available through the Sites, except as expressly
            permitted under these Terms. </p>
        <p> All trademarks, service marks, and logos displayed on the Sites (the “Marks”) are
            the exclusive property of Endzert or their respective third-party owners. Except for your right to view
            Marks on our Site, you are not granted any rights to the Marks. Nothing in the Sites grant, by
            implication, estoppel, or otherwise, any license or right to use any Marks displayed on the Sites. </p>
        <h3>3. USER-SUBMITTED MATERIALS</h3>
        <p> The Sites include functionality to enable you to upload your résumé, other
            employment- and career-related information, job orders, and/or other information related to your
            Organization, and may also enable you to submit comments and materials through interactive features such
            as message boards, other forums, and chatting, commenting and other messaging functionality (all such
            résumés, information, comments, and materials are “Submitted Materials”). For clarity, you retain
            ownership of your Submitted Materials. You hereby grant Endzert a worldwide, royalty-free, fully
            paid-up, non-exclusive, perpetual, irrevocable, transferable and fully sublicensable (through multiple
            tiers) license, without additional consideration to you or any third party, to reproduce, distribute,
            perform and display (publicly or otherwise), create derivative works of, adapt, modify and otherwise use
            and exploit such Submitted Materials, in any format or media now known or hereafter developed, and for
            any purpose (including promotional purposes, such as testimonials). </p>
        <p> In addition, if you provide to us any ideas, proposals, suggestions, bugs, or
            other materials (“Feedback”), whether related to the Sites or otherwise, such Feedback will be deemed
            Submitted Materials, and you hereby acknowledge and agree that such Feedback is not confidential, and
            that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does not
            place Endzertt under any fiduciary, financial, or other obligation. </p>
        <p> You represent and warrant that you have all rights necessary to grant the licenses
            granted in this section, and that your Submitted Materials, and your provision thereof through and in
            connection with the Sites, are complete and accurate, and are not fraudulent, tortious or otherwise in
            violation of any applicable law or any right of any third party. You further irrevocably waive any
            “moral rights” or other rights with respect to attribution of authorship or integrity of materials
            regarding each Submission that you may have under any applicable law under any legal theory. </p>
        <p> Endzertt may (but has no obligation to) screen, monitor, evaluate and remove any
            Submitted Materials at any time and for any reason, or analyze your access to and use of the Sites. We
            may disclose information regarding your access to and use of the Sites, and the circumstances
            surrounding such access and use, to anyone for any reason or purpose. </p>
        <p> For clarity, certain information that you provide to Endzertt through the Sites may
            also be governed by Endzertt’s Privacy Policy, located at roberthalf.com/privacy-policy. You represent
            and warrant that any information you provide in connection with the Sites are and will remain accurate
            and complete, and that you will maintain and update such information as needed. </p>
        <h3>4. RULES OF CONDUCT</h3>
        <p> In connection with the Sites, you agree NOT to: </p>
        <ol>
            <li> Post, transmit or otherwise make available through or in connection with the
                Sites any materials that are or may be: (a) threatening, harassing, degrading, hateful or
                intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory,
                libelous, fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise
                objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or privacy
                or any other proprietary right, without the express prior written consent of the applicable owner.
            </li>
            <li> Insert or permit to be inserted, any malicious or harmful software into the
                Sites, including but not limited to any software designed to (a) make unauthorized changes to or
                cause damage to the Sites or Third-Party Materials as defined below; (b) copy, provide unauthorized
                access to, or prevent authorized access to the Sites; or (c) prevent detection of any unauthorized
                invasion of the Sites (“Malware”). </li>
            <li> Use the Sites for any unauthorized commercial purpose, including competing
                with Endzert, or for any purpose that is fraudulent or otherwise tortious or unlawful. </li>
            <li> Harvest or collect information about users of the Sites. </li>
            <li> Interfere with or disrupt the operation of the Sites or the servers or
                networks used to make the Sites available, including by hacking or defacing any portion of the Site;
                or violate any requirement, procedure or policy of such servers or networks. </li>
            <li> Restrict or inhibit any other person from using the Sites. </li>
            <li> Reproduce, modify, adapt, translate, create derivative works of, sell, rent,
                lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Sites
                except as expressly authorized herein. </li>
            <li> Reverse engineer, decompile or disassemble any portion of the Site, except
                where such restriction is expressly prohibited by applicable law. </li>
            <li> Remove any copyright, trademark or other proprietary rights notice from the
                Sites. </li>
            <li> Frame or mirror any portion of the Sites, or otherwise incorporate any
                portion of the Sites into any product or service. </li>
            <li> Systematically download and store Site content. </li>
            <li> Use any robot, spider, site search/retrieval application or other manual or
                automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather Site content, or
                reproduce or circumvent the navigational structure or presentation of the Sites, without Endzert’s
                express prior written consent. Notwithstanding the foregoing, and subject to compliance with any
                instructions posted in the robots.txt file located in the Site’s root directory, Endzert grants to
                the operators of public search engines permission to use spiders to copy materials from the Sites
                for the sole purpose of (and solely to the extent necessary for) creating publicly available,
                searchable indices of such materials, but not caches or archives of such materials. Endzert reserves
                the right to revoke such permission either generally or in specific cases, at any time and without
                notice. </li>
        </ol>
        <h3>5. THIRD-PARTY MATERIALS</h3>
        <p> The Sites may make available or provide links to third party websites, content,
            services, or information (“Third-Party Materials”). Endzert does not control, and is not responsible for,
            any Third-Party Materials and the availability of any Third-Party Materials on the Site does not imply
            endorsement of, or affiliation with the provider of Third-Party Materials. Your use of Third-Party
            Materials is at your own risk. </p>
        <p> This site is protected by the reCAPTCHA service and your use of reCAPTCHA on the
            Site is subject to Google LLC’s (“Google”) Terms of Service and Privacy Policy. We use the reCAPTCHA
            service provided by Google to protect the Site and your submissions via internet submission forms on
            this Site. reCAPTCHA checks if you are a person in order to prevent certain Site functions from being
            abused. This plugin query includes the sending of IP address and other data such as your device data,
            cookies placed on your device (such as from Google), language and date information, installed plug-ins,
            and javascript objects which the reCAPTCHA service uses to evaluate your use of the Site. By using the
            reCAPTCHA service, you consent to the processing of data about you by Google in the manner and for the
            purposes set out above. If you do not agree, then do not use or access this Site. </p>
        <h3>6. INDEMNIFICATION</h3>
        <p> You agree to indemnify, defend, and hold harmless Endzert, and its affiliates,
            officers, directors, agents, and employees from any claims, liabilities, damages, judgments, awards,
            losses, costs, expenses and fees (including attorneys’ fees) arising out of or relating to your access
            to or use of, or activities in connection with, the Sites (including any Submitted Materials), or your
            violation or alleged violation of these Terms or the Privacy Policy. You may not enter into a settlement
            of a claim that involves a resolution other than one consisting solely of a monetary settlement, without
            Endzert’s prior written consent. This indemnity obligation shall survive the termination of this
            Agreement </p>
        <h3>7. DISCLAIMER</h3>
        <p> THE SITES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, AND Endzert EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, WITH
            RESPECT TO THE SITES TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE, AND WARRANTIES ARISING
            FROM COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OR TRADE. </p>
        <p> While we try to maintain the timeliness, integrity and security of the Sites, we
            do not guarantee that the Sites are or will remain updated, complete, correct or secure, or that access
            to the Sites will be uninterrupted. The Sites may include inaccuracies, errors and materials that
            violate or conflict with these Terms. Additionally, third parties may make unauthorized alterations to
            the Sites. </p>
        <h3>8. LIMITATION OF LIABILITY</h3>
        <p> YOU AGREE Endzert AND ITS AFFILIATES, INCLUDING THEIR RESPECTIVE EMPLOYEES,
            OFFICERS, AGENTS AND DIRECTORS, WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
            EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
            LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS OF OTHER
            INTANGIBLES, OR LOSS OF SECURITY OF SUBMITTED MATERIALS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD
            PARTIES OF ANY SUBMITTED MATERIALS), EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR
            LOSSES. WITHOUT LIMITING THE FOREGOING, YOU FURTHER AGREE Endzert WILL NOT BE LIABLE FOR DAMAGES OF ANY
            KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SITES OR FROM ANY THIRD-PARTY MATERIALS,
            INCLUDING FROM ANY MALWARE OR ANY VIRUS, WORM, HACK, OR MALICIOUS SOFTWARE THAT MAY BE TRANSMITTED IN
            CONNECTION THEREWITH. YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION
            WITH THE SITES IS TO STOP USING THE SITES. YOU AGREE THE MAXIMUM AGGREGATE LIABILITY OF Endzert FOR ALL
            DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
            WILL BE $100. </p>
        <p> Applicable law may not allow for limitations on certain implied warranties, or
            exclusions or limitations of certain damages; solely to the extent that such law applies to you, some or
            all of the above disclaimers, exclusions or limitations may not apply to you, and you may have certain
            additional rights. </p>
        <h3>9. TERMINATION</h3>
        <p> Endzert may terminate or suspend your access to the Sites at any time, with or
            without cause or notice, including if we believe that you have violated or acted inconsistently with the
            letter or spirit of these Terms. Upon any such termination or suspension, (a) your right to access and
            use the Sites will immediately cease; (b) Endzert may immediately deactivate or delete your user name,
            password and account; (c) Endzert will be under no obligation to maintain or provide you with access to
            any materials associated with your account (including Submitted Materials), and may retain or delete
            such materials in Endzert’s sole discretion; and (d) except for the license granted to you to access and
            use the Sites, the remaining provisions of these Terms will survive and continue in effect. </p>
        <h3>10. NOTICE OF COPYRIGHT INFRINGEMENT</h3>
        <p> The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for
            copyright owners who believe that material appearing on the internet infringes their rights under U.S.
            copyright law. If you believe in good faith that materials available on the Sites infringe your
            copyright, you (or your agent) may send to Endzert a written notice by mail, email or fax, requesting
            that Endzert remove such material or block access to it. If you believe in good faith that someone has
            wrongly filed a notice of copyright infringement against you, the DMCA permits you to send to Endzert a
            counter-notice. Notices and counter-notices must meet the then-current statutory requirements imposed by
            the DMCA. See http://www.copyright.gov/ for details. Notices and counter-notices must be sent in writing
            to: </p>
        <p> Copyright Agent </p>
        <p> Endzert International Inc. </p>
        <p> Email: contact@endzert.com </p>
        <p> We suggest that you consult your legal advisor before filing a DMCA notice or
            counter-notice. </p>
        <h3>11. GOVERNING LAW; JURISDICTION</h3>
        <p> These Terms are governed by, and will be construed in accordance with, the laws of
            (a) the state where you use or access the Sites; or (b) if a dispute arises relating to (i) your
            consideration for placement on a job, (ii) your placement on a job, or (iii) your job order, the state
            in which the job opportunity at issue is located. </p>
        <h3>12. GENERAL</h3>
        <p> These Terms do not, and will not be construed to, create any partnership, joint
            venture, employer-employee, agency or franchisor-franchisee relationship between you and Endzert. If any
            provision of these Terms is found to be unlawful, void or for any reason unenforceable, that provision
            will be deemed severable from these Terms and will not affect the validity and enforceability of any
            remaining provision. You may not assign, transfer or sublicense any or all of your rights or obligations
            under these Terms without our express prior written consent. We may assign, transfer or sublicense any
            or all of our rights or obligations under these Terms without restriction. No waiver by either party of
            any breach or default under these Terms will be deemed to be a waiver of any preceding or subsequent
            breach or default. Any heading, caption or section title contained herein is for convenience only, and
            in no way defines or explains any section or provision. All terms defined in the singular will have the
            same meanings when used in the plural, where appropriate and unless otherwise specified. Any use of the
            term “including” or variations thereof in these Terms will be construed as if followed by the phrase
            “without limitation.” These Terms, including any terms and conditions incorporated herein, constitute
            the entire agreement between you and Endzert relating to the subject matter hereof, and supersede any and
            all prior or contemporaneous written or oral agreements or understandings between you and Endzert
            relating to such subject matter. Notices to you (including notices of changes to these Terms) may be
            made via posting to the Sites or by email (including in each case via links), or by regular mail.
            Without limitation, a printed version of these Terms and of any notice given in electronic form will be
            admissible in judicial or administrative proceedings based upon or relating to these Terms to the same
            extent and subject to the same conditions as other business documents and records originally generated
            and maintained in printed form. Endzert will not be responsible for any failure to fulfill any obligation
            due to any cause beyond its control. </p>
        <h3>13. ACCOUNT AND PASSWORD</h3>
        <p> You may need to register for an account to use all or portions of the Sites. We
            may reject, or require that you change, any username, password or other information that you provide to
            us in registering. You, and not Endzert, are responsible for the security of and any use or misuse of
            your username or password. In particular, it is your sole responsibility to (a) maintain the
            confidentiality of your account login and password; (b) frequently update and revise your password; and
            (c) promptly notify Endzert if there is any unauthorized use of your account(s) or any breach of security
            by contacting us in accordance with the “CONTACT US” section below. </p>
        <h3>14. CUSTOMERS</h3>
        <h4>14.1 Registration</h4>
        <p> If you are a business client of Endzert to whom we provide services (“Customer”),
            you may register with Endzert on the Sites. If you are a Customer using the Sites to engage contract
            talent online, the Terms of Service for Contract Talent state the terms and conditions governing your
            use of contract talent services, including your use of and access to the Site listed above. </p>
        <p> Once registered, you may be able to: </p>
        <ol>
            <li>create new orders;</li>
            <li>review and modify existing orders;</li>
            <li>review details for each order, including Candidate Information (as that term
                is defined in Section 15.1);</li>
            <li>update your contact information;</li>
            <li>view candidate recommendations;</li>
            <li>request to interview and/or engage candidates and</li>
            <li>review and approve time reports for Endzert candidates currently working on
                jobs with you.</li>
        </ol>
        <p> You agree that you are solely responsible for all activities undertaken within
            your Customer account, including but not limited to any charges and additional fees incurred for
            extending or otherwise modifying jobs and creating new job orders. </p>
        <h4>14.2 Payments Through the Sites</h4>
        <p> If you wish to pay an outstanding invoice through the Sites (each such payment, a
            “Transaction“), you may be asked to supply certain information relevant to your Transaction including,
            without limitation, your bank information, routing number, bank account number, bank account type,
            credit card number, card verification number, the expiration date of your credit card, your billing
            address, billing phone number, and email. YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE
            ANY BANK ACCOUNT INFORMATION OR CREDIT CARD(S) OR OTHER PAYMENT METHOD(S) UTILIZED IN CONNECTION WITH
            ANY TRANSACTION. To process your payment, Endzert uses CyberSource, an independent third party and
            subsidiary of Visa Inc. CyberSource controls the security and performance of its payment processing
            services. Please refer to the CyberSource terms, conditions and privacy policies governing your payment
            located at www.cybersource.com. Verification of information may be required prior to the acknowledgment
            or completion of any Transaction. Endzert or our agents may call or contact you regarding your account or
            your Transactions. You agree that we may place such calls or contact you relating to the Transaction.
        </p>
        <h3>15. CANDIDATES</h3>
        <h4>15.1 Registration and Use of Sites</h4>
        <p> If you register as a candidate on the Sites (“Candidate”), you may opt to enroll
            in or be automatically enrolled in other Endzert offerings designed to assist you in finding a job. As
            part of the enrollment process, we may require you to provide us with certain information including, but
            not limited to, your resume, name, email address, phone number, work history, education, experience,
            pay/compensation expectations and geographic location (the “Candidate Information“). Once you have
            enrolled, Endzert personnel may reach out to you via the email address or phone number your provided, and
            work with you to finalize your Candidate profile and continue to work with you throughout your use of
            the Sites. </p>
        <p> You may be able to update your job search criteria and preferences, upload and
            update your resume, receive alerts, update your availability, view the status of your application, and
            provide us with additional information about you. Your Candidate Information may be viewed by Customers
            on the Sites according to Endzert’s matching algorithm. Customers may have the opportunity to communicate
            with you directly through the Sites. You may receive notification from the Sites regarding a Customer’s
            interest in you and details about the job. You may, in your sole discretion, respond to any Customer
            that has contacted you via the Sites, to accept or reject any interview requests. All requests for
            interviews by Customers through the Sites do not guarantee a final job or job offer, or a job offer at
            any particular level of compensation. </p>
        <p> You agree that you are solely responsible for all activities undertaken within
            your account(s). Any initial communication between you and any Customer matched with shall be made
            exclusively through the Sites. Further communication with any Customer may only take place outside of
            the Sites after a Customer requests an interview with you and you accept the interview through the
            Sites. You further agree not to independently attempt to contact any Customer through an alternative
            means outside the Sites. </p>
        <h4>15.2 No Guarantee</h4>
        <p> Further, without limiting the foregoing, Endzert (a) does not guarantee that you
            will receive any employment or job offers through the Sites; (b) will not be responsible for any job
            offers or listings, initial screenings, hiring decisions, or actual employment presented by third
            parties; and (c) is neither your employer nor your agent based solely on your usage of the Sites. You
            must use your own judgment in evaluating any prospective employers and any Third-Party Materials. </p>
        <h4>15.3 Authorization and Certification</h4>
        <p> You authorize Endzert, Customers, and their respective agents to make
            investigations and inquiries into your work and educational history and other related matters as may be
            necessary in arriving at a decision to place you on a job. Endzert may use a third-party vendor to
            perform such investigations/inquiries. If a third-party vendor is used, you will be provided additional
            disclosures and authorization forms as required by applicable laws prior to a third party performing
            such investigations/inquiries. </p>
        <p> You release employers, schools, and other persons from all liability in responding
            to inquiries connected with your application and you specifically authorize the release of information
            by any schools, businesses, individuals, services or other entities listed by you in the Candidate
            Information. Furthermore, you authorize Endzert and its agents to release any reference information to
            Customers who request such information for purposes of evaluating your credentials, skills and
            experience and you acknowledge that Customers may reach out to you directly. </p>
        <p> You certify as to the accuracy of the Candidate Information and in any resume or
            other work history information. You understand that any misstatement of fact may cause you to be refused
            a job by Customer, to lose your job once placed on a job with Customer, or may result in removal of your
            account from the Sites. You are responsible for keeping Candidate Information accurate, complete, and
            up-to-date at all times. </p>
        <h4>15.4 No Charge</h4>
        <p> There is no charge for Candidates to use the Sites. Neither Endzert nor Customers
            will require a payment from you. </p>
        <h4>15.5 Confidentiality</h4>
        <p> You agree that by using the Sites as a Candidate, Endzert may contact you via the
            phone number (via text, call, or video conference) provided to Endzert, including cell phone numbers. You
            also agree that by using the Sites, Endzert may provide you with job opportunity or general employment
            information by sending email to you at the email address provided to Endzert. </p>
        <h4>15.6 Employment and Income Verification</h4>
        <p> If you were placed on a contract/temporary job with a Endzert business client and
            you were not an independent contractor, and you need to verify your employment or income on that job,
            please send an email to contact@endzert.com </p>
        <h3>16. Endzert DIRECT</h3>
        <h4>16.1 Introduction</h4>
        <p> The Endzert Direct functionality within the Sites, including within Endzert’s mobile
            application (the “App”), (individually or together “ Half Direct”) is operated by Endzert. Endzert Direct
            is an online tool that allows Customers to connect directly with Candidates that have been matched to
            their job orders using proprietary algorithms. In this Section 16, the term “Candidate” refers to users
            of the Sites who are seeking full-time employment opportunities. In this Section 16, the term “Customer”
            refers to those interested in receiving permanent placement services from Endzert through Endzert Direct.
        </p>
        <p> If you elect to utilize Endzert Direct, you agree to these additional terms in this
            section titled, “Endzert DIRECT.” </p>
        <h4>16.2 Description of Services</h4>
        <p> After a Candidate creates an account and completes a profile on the Sites,
            Customer may view his/her Candidate Information according to Endzert’s matching algorithm. Customers will
            have the opportunity to engage with Candidates with skills and experience that match Customer’s job
            requirements. A Candidate may, in his/her sole discretion, respond to any Customer that has contacted
            him or her via the Sites, to accept or reject any interview requests. All requests for interviews by
            Customers through Endzert Direct do not guarantee a final job or job offer, or a job offer at any
            particular level of compensation. </p>
        <p> Endzert Direct enables Customers to connect with the Endzert team and/or directly
            with Candidates. Candidates and Customers are solely responsible for the information provided in any
            resume or profile, any requests for interviews or offers made, any issues arising from an acceptance or
            denial of employment, and any other issues arising from the use of the Sites. Endzert does not provide
            any guarantee or assurances regarding the hiring or interviewing process. Endzert does not have input
            into the Customer’s hiring process. Endzert is not responsible for and does not guarantee job postings or
            wages or benefits presented by Customers. </p>
        <p> Endzert is not a third party to or liable for any agreements between any Customers
            and Candidates, regardless of whether or not Endzert receives a fee from Customer in connection with the
            transaction. Candidates will not pay any fees to Endzert or a Customer. </p>
        <h4>16.3 Additional Terms of Service</h4>
        <p> If you are a Customer using Endzert Direct, the Terms of Service for Customers
            state additional terms and conditions governing your use of Endzert’s placement services, including your
            use of and access to the Site. If you have a question regarding those terms, please send an email to
            contact@endzert.com. </p>
        <h4>16.4 Contact Information</h4>
        <p> If you have a question regarding Endzert Direct, please contact your Success
            Manager or send an email to contact@endzert.com. </p>
        <h3>17. MOBILE APPLICATION</h3>
        <p> If you elect to utilize the App, you agree to these additional terms stated in
            this section entitled “MOBILE APPLICATION.” </p>
        <h4>17.1 Account and Password</h4>
        <p> We will be entitled to assume that anyone logging into your account on the App
            using your username and password is either you or someone logging in with your permission. If you fail
            to keep your username and password confidential, or if you share your username and password with someone
            else (whether intentionally or unintentionally), you accept full responsibility for the consequences of
            this. We will not be responsible to you for any loss that you suffer as a result of an unauthorized
            person accessing your account and/or using our App and we accept no responsibility for any losses or
            harm resulting from its unauthorized use, whether fraudulently or otherwise. </p>
        <p> You understand that if you delete your account or the App from your device, or if
            we delete your account in accordance with these Terms, you may lose access to any data previously
            associated with your account (including, without limitation, your job applications and work history).
        </p>
        <h4>17.2 Push Messages</h4>
        <p> If you download the App, you may receive push notifications sent to you outside or
            inside the App which may include alerts, badges, banners and sounds (“Push Messages“). If you agree to
            allow Push Messages, then the App will generate Push Messages on your mobile device. You may control the
            Push Messages in your device’s or the App’s settings. Some of the Push Messages may be related to your
            location. Your carrier may charge standard data and other fees for use of Push Messages, and these fees
            may appear on your mobile bill or be deducted from your pre-paid balance. Your carrier may prohibit or
            restrict certain Push Messages and certain Push Messages may be incompatible with your carrier or mobile
            device. Contact your carrier with questions regarding these issues. You may discontinue Push Messages in
            your device’s or the App’s settings or by deleting the App. We may collect information related to your
            use of Push Messages. If you have registered for Push Messages, you agree to notify us of any changes to
            your mobile number, as applicable, and update your account on the App to reflect this change. </p>
        <h3>18. ONLINE TIME REPORTING AND APPROVAL SYSTEM</h3>
        <h4>18.1 Introduction</h4>
        <p> The Online Time Reporting and Approval System is an online tool that allows
            Endzert’s employees, independent contractors, and business clients to enter, review, and approve time
            reports (the “System”). “Employee” refers to users of the System who are employed by Endzert. The term
            “Independent Contractor” refers to users of the System who are working as independent contractors
            through Endzert. Within this section entitled “ONLINE TIME REPORTING AND APPROVAL SYSTEM,” the term
            “Customer” refers to users of the System who are business clients of Endzert. If you are not an
            authorized Employee, Independent Contractor, or Customer in the United States, please do not use the
            System. Unauthorized access or usage is strictly prohibited, and violators are subject to civil
            liabilities and criminal prosecution. </p>
        <h4>18.2 Direct Deposit</h4>
        <p> If you are an Employee or Independent Contractor, you may receive payment of wages
            using a variety of methods including direct deposit. You may also update your direct deposit information
            or enter new direct deposit information. You may make changes to your default payment method in the App
            by going to the Account Information section of the My Account menu. You are responsible for maintaining
            the accuracy of information we have on file, and you consent to Endzert updating such stored information
            from time to time based on information provided by you, your bank or other payments services providers.
            You will only provide to us information about payment methods that you are authorized to use. Your bank
            may charge you a fee for using direct deposit, which you are solely responsible for. </p>
        <h4>18.3 Data Entry and Approval</h4>
        <p> After a user creates an account in the System and a job order is created,
            Employees and Independent Contractors may opt to enter the days and hours worked on a time report
            through the System. A Customer may opt to receive, review, and approve the time report in the System.
        </p>
        <h4>18.3.1 Employee and Contractor Data Entry</h4>
        <p> If you are an Employee or Independent Contractor, you certify that the days and
            hours entered on the time report through the System are correct, complete, and were worked by you. You
            understand that a misstatement of fact may cause you to lose your employment or contract with Endzert or
            may result in removal of your account from the System. </p>
        <h4>18.3.2 Customer Data Entry</h4>
        <p> If you are a Customer, by clicking the submit button, you acknowledge your receipt
            and approval of the time report. You understand that a misstatement of fact may cause Endzert to
            discontinue providing services or may result in removal of your account from the System. </p>
        <h4>18.3.3 General Data Entry Terms</h4>
        <p> By submitting your hours, comments, data and/or approval of time report
            information to the System (the “Time Report Information“), you hereby grant Endzert a perpetual,
            non-exclusive, irrevocable, royalty free, worldwide license and right to use, copy, modify, display,
            distribute, download, store, reproduce, transmit, publish, transfer, adapt, create derivative works in
            any manner, by any means, in entirety or a portion of, of your Time Report Information. </p>
        <p> You acknowledge and accept that Endzert will share the Time Report Information with
            third parties for Endzert’s business purposes, including but not limited to Endzert’s business clients,
            advisors, agents, and consultants. Endzert will not sell your Time Report Information to any third party
            for marketing purposes.Endzert contact@endzert.com Endzert </p>
    </div>
</section>

<br>
<br>