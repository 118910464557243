<app-navbar-one></app-navbar-one>

<div class="faq-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>FAQ</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>FAQ</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Frequently Asked Questions</h2>
                <div class="topic">
                    <div class="open">
                        <h3 class="question">1. What services do you offer?</h3>
                        <span class="faq-t"></span>
                    </div>
                    <p class="answer">We offer a wide range of services including business consultancy, marketing, human
                        resources, project management, and online business solutions.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">2. How much do your services cost?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">Our prices vary depending on the specific service you require. Please contact us
                        to discuss your needs and receive a quote.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">3. How long does it take to see results?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">The time it takes to see results varies depending on the service and your specific
                        goals. Our team will work with you to create a realistic timeline and provide regular updates on
                        progress.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">4. How do you ensure the security of my information?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">We take the security and confidentiality of our clients' information very
                        seriously. We use the latest technology and best practices to ensure your data is safe and
                        protected at all times.</p>
                </div>

                <div class="topic">
                    <div class="open">
                        <h3 class="question">5. How do I get started?
                        </h3><span class="faq-t"></span>
                    </div>
                    <p class="answer">Simply contact us through our website or give us a call to schedule an initial
                        consultation. We will discuss your needs and develop a customized plan to help you achieve your
                        business goals.</p>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>